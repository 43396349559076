<template>
  <TilesBlock
    v-if="item.fields.tiles"
    :tiles="tiles"
    v-bind="
      ContentfulLivePreview.getProps({
        entryId: item.sys.id,
        fieldId: 'tiles'
      })
    "
  ></TilesBlock>
</template>

<script setup lang="ts">
import { TilesBlock } from '@hypercodestudio/basler-components';
import type { TileProps } from '@hypercodestudio/basler-components/dist/components/modules/Tile.vue';
import type { ITiles } from '~/lib/ContentfulService';
import { isDefined } from '~/utils/guards/isDefined';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { ContentfulLivePreview } from '@contentful/live-preview';

interface Props {
  item: ITiles;
}

const props = defineProps<Props>();

const tiles = computed(() =>
  props.item.fields
    ? props.item.fields.tiles
        ?.filter((entry) => !isEntryResolveError(entry) && isDefined(entry))
        ?.map(
          (tile) =>
            ({
              headerIcon: tile.fields?.icon ?? '',
              headline: tile.fields?.title,
              content: tile.fields?.text ?? ''
            } satisfies TileProps)
        )
    : []
);
</script>
